<template>
	<Page class="page-password-reset-link">

		<Error v-if="!this.$route.query.token || !this.$route.query.email">
			<v-btn
					:to="paths().register"
					large
					outlined
					class="btn-submit"
			>Go to Register
			</v-btn>
			<v-btn
					:to="paths().login"
					large
					outlined
					class="btn-submit"
			>Go to Login
			</v-btn>
		</Error>

		<LayoutAuth title="Reset Password" v-if="this.$route.query.token && this.$route.query.email && !auth.passwordReset.success">
			<v-form
					ref="form"
					v-model="valid"
					lazy-validation
			>
				<v-text-field
						v-model="passwordNew"
						label="New Password"
						:rules="[
                          validationRules.required,
                          validationRules.password.lowercase,
                          validationRules.password.uppercase,
                          validationRules.password.digit,
                          validationRules.password.min,
                        ]"
						required
						@click:append="passwordNewShow = !passwordNewShow"
						:append-icon="passwordNewShow ? 'mdi-eye' : 'mdi-eye-off'"
						:type="passwordNewShow ? 'text' : 'password'"
						color="none"
						@keydown.space.prevent
				></v-text-field>
				<v-text-field
						v-model="passwordNewConfirmation"
						type="password"
						label="Confirm New Password"
						:rules="[
                          validationRules.required,
                          passwordNew === passwordNewConfirmation || 'Password must match'
                        ]"
						required
						color="none"
						@keydown.space.prevent
				></v-text-field>
				<v-btn
						type="submit"
						large
						outlined
						:disabled="!valid || auth.passwordReset.isLoading"
						:loading="auth.passwordReset.isLoading"
						@click.prevent="onSubmit"
						class="btn-submit"
				>
					Submit
				</v-btn>
			</v-form>
		</LayoutAuth>

		<div class="reset-password-success" v-if="auth.passwordReset.success">
			<Title title="Password has been changed successfully!" />
			<v-btn
					:to="paths().login"
					large
					outlined
					class="btn-submit"
			>Go to login</v-btn>
		</div>
	</Page>
</template>

<script>
	import Page from "../components/layouts/LayoutPage";
	import LayoutAuth from "../components/layouts/LayoutAuth";
	import Error from "../components/common/Error";
	import { mixinValidationRules } from "../mixins/mixinValidationRules";
	import {
		mapActions,
		mapState
	} from "vuex";
	import { mixinPaths } from "../mixins/mixinPaths";
		import Title from "../components/common/Title";


	export default {
		name: 'PasswordReset',
		components: { Title, Error, LayoutAuth, Page },
		mixins: [
			mixinValidationRules,
			mixinPaths,
		],
		computed: {
			...mapState( [
				'auth'
			] ),
		},
		data() {
			return {
				valid: false,
				passwordNew: '',
				passwordNewShow: false,
				passwordNewConfirmation: '',
			};
		},
		methods: {
			...mapActions( [
				'requestPasswordReset',
				'requestPasswordResetReset',
			] ),
			onSubmit() {
				if ( this.$refs.form.validate() ) {
					this.$store.dispatch(
						"requestPasswordReset",
						{
							email: this.$route.query.email,
							token: this.$route.query.token,
							password: this.passwordNew,
							password_confirmation: this.passwordNewConfirmation,
						}
					);
				}
			},
		},
		destroyed() {
			this.$store.dispatch( "requestPasswordResetReset" );
		},
	};
</script>

<style lang="scss">
	.reset-password-success {
		.app-title {
			color: $c-success;

			@include breakpoint(overPhone) {
				@include center-block;
				max-width: 50rem;
			}
		}
	}
</style>
