var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Page',{staticClass:"page-password-reset-link"},[(!this.$route.query.token || !this.$route.query.email)?_c('Error',[_c('v-btn',{staticClass:"btn-submit",attrs:{"to":_vm.paths().register,"large":"","outlined":""}},[_vm._v("Go to Register ")]),_c('v-btn',{staticClass:"btn-submit",attrs:{"to":_vm.paths().login,"large":"","outlined":""}},[_vm._v("Go to Login ")])],1):_vm._e(),(this.$route.query.token && this.$route.query.email && !_vm.auth.passwordReset.success)?_c('LayoutAuth',{attrs:{"title":"Reset Password"}},[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-text-field',{attrs:{"label":"New Password","rules":[
                          _vm.validationRules.required,
                          _vm.validationRules.password.lowercase,
                          _vm.validationRules.password.uppercase,
                          _vm.validationRules.password.digit,
                          _vm.validationRules.password.min ],"required":"","append-icon":_vm.passwordNewShow ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.passwordNewShow ? 'text' : 'password',"color":"none"},on:{"click:append":function($event){_vm.passwordNewShow = !_vm.passwordNewShow},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"space",32,$event.key,[" ","Spacebar"])){ return null; }$event.preventDefault();}},model:{value:(_vm.passwordNew),callback:function ($$v) {_vm.passwordNew=$$v},expression:"passwordNew"}}),_c('v-text-field',{attrs:{"type":"password","label":"Confirm New Password","rules":[
                          _vm.validationRules.required,
                          _vm.passwordNew === _vm.passwordNewConfirmation || 'Password must match'
                        ],"required":"","color":"none"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"space",32,$event.key,[" ","Spacebar"])){ return null; }$event.preventDefault();}},model:{value:(_vm.passwordNewConfirmation),callback:function ($$v) {_vm.passwordNewConfirmation=$$v},expression:"passwordNewConfirmation"}}),_c('v-btn',{staticClass:"btn-submit",attrs:{"type":"submit","large":"","outlined":"","disabled":!_vm.valid || _vm.auth.passwordReset.isLoading,"loading":_vm.auth.passwordReset.isLoading},on:{"click":function($event){$event.preventDefault();return _vm.onSubmit($event)}}},[_vm._v(" Submit ")])],1)],1):_vm._e(),(_vm.auth.passwordReset.success)?_c('div',{staticClass:"reset-password-success"},[_c('Title',{attrs:{"title":"Password has been changed successfully!"}}),_c('v-btn',{staticClass:"btn-submit",attrs:{"to":_vm.paths().login,"large":"","outlined":""}},[_vm._v("Go to login")])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }